import React from 'react';
// import { Link } from 'react-router-dom';

const TopNav = () => {
  return (
    <div className="footer">
      <h1>Say Hi! → → → <a className="email" href="mailto:lbyalaya@gmail.com">lbyalaya@gmail.com</a></h1>
    </div>
  )
}

export default TopNav;
